.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.sticky-nav {
  position: sticky;
  top: 0;
}

.fixed-nav {
  position: fixed;
}

.notify-item {
  position:relative;
  padding-top:15px;
  display:inline-block;
}
.notify-badge{
  position: absolute;
  right:-10px;
  top:5px;
  background:#ffbf00;
  text-align: center;
  font-weight: bold;
  border-radius: 5px 5px 5px 5px;
  color:#333333;
  
  padding: 0px 5px 0px 5px;
  font-size:16px;
}


.review-item {
  position:relative;
  padding-top:15px;
  display:inline-block;
}
.review-badge{
  position: absolute;
  right:-10px;
  top:5px;
  border-radius: 5px 5px 5px 5px;
  padding: 0px 5px 0px 5px;
}